
import { computed, defineComponent, ref, watch } from "vue";
import UserCard from "@/components/UserCard.vue";
import { setAuthorizationHeader } from "@/components/api/baseclient";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import ModalAddPayment from "@/components/modalAddPayment/index.vue";

export default defineComponent({
  name: "Navbar",
  components: {
    UserCard,
    ModalAddPayment,
  },
  setup() {
    const modal = ref(false);
    const store = useStore();
    const currencyModal = ref(false);
    const dropdown = ref(null);
    const currencyDropdown = ref(null);
    const isMenuOpen = ref(false);
    const modalPlatform = ref(null);
    const roles = computed(() => store.state.user.role);
    const payments = computed(
      () => store.state.profile?.data?.company?.payment_methods?.data
    );
    const paywall = ref(false);
    const profile = store.state.profile.data;
    const exchangeRate = computed(() => store.state.profile.data.average_exchange_rate);
    const showTooltip = ref(false);

    onClickOutside(dropdown, (event) => {
      modal.value = false;
    });

    onClickOutside(currencyDropdown, (event) => {
      currencyModal.value = false;
    });

    onClickOutside(modalPlatform, (event) => {
      isMenuOpen.value = false;
    });

    function goToSettings() {
      window.location.href = process.env.VUE_APP_ACCOUNT_SETTINGS;
    }

    watch(payments, (newVal) => {
      if (newVal.length === 0) {
        paywall.value = true;
      } else {
        paywall.value = false;
      }
    });

    return {
      modal,
      dropdown,
      currencyModal,
      currencyDropdown,
      isMenuOpen,
      modalPlatform,
      roles,
      paywall,
      profile,
      goToSettings,
      showTooltip,
      exchangeRate,
    };
  },
  data: () => ({
    showModal: false,
    showCurrencyModal: false,
    currencies: ["usd", "mxn"],
  }),
  methods: {
    logOut() {
      this.$store.dispatch("logoutUser");
    },
    changeCurrency(newCurr: string) {
      this.$store.dispatch("changeCurrency", newCurr);
      this.currencyModal = false;
    },
    goToLink(link: string) {
      this.showModal = false;
      this.$router.push(link);
    },
    changePlatform() {
      window.location.replace(process.env.VUE_APP_TALENT_SOURCING_URL);
    },
  },
  computed: {
    email() {
      return this.$store.getters.getEmail;
    },
    userId() {
      return this.$store.getters.getUserId;
    },
    userFirstName() {
      return this.$store.getters.getUserFirstName;
    },
    userLastName() {
      return this.$store.getters.getUserLastName;
    },
    currency() {
      return this.$store.getters.getCurrency;
    },
  },
  mounted() {
    this.$store.dispatch("setUser");
    this.$store.dispatch("getProfile");
    if (this.profile?.first_name) {
      let intercomScript = document.createElement("script");
      intercomScript.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

      document.head.appendChild(intercomScript);

      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        name: `${this.profile.first_name} ${this.profile.last_name}`,
        email: this.profile.email,
        company: {
          id: this.profile.company.id,
          name: this.profile.company.name,
          plan: this.profile.company.last_subscription?.nickname
            ? this.profile.company.last_subscription.nickname
            : "",
          website: `${this.profile.company.company_products[0]} ${
            this.profile.company.company_products[1] || ""
          }`,
          industry: this.profile.company.country,
        },
        user_hash: this.profile.intercom_hash
      });
    }
  },
});
